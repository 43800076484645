(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name deposits.list.controller:DepositsListCtrl
   *
   * @description
   *
   */
  angular
    .module('events.vehicles.deposits.list')
    .controller('DepositsListCtrl', DepositsListCtrl);

  function DepositsListCtrl($scope, $state, $stateParams, $mdDialog, $filter, $http, PAGE_SIZE, API_URL, Restangular, Blob, FileSaver, event) {
    var vm = this;

    vm.event = event.data;
    vm.vehicle = null;
    vm.deposits = [];
    vm.exportExcel = exportExcel;
    vm.exportCSV = exportCSV;
    vm.goToAdd = goToAdd;

    // md-data-table
    vm.meta = {
      limit: PAGE_SIZE,
      page: $stateParams.p,
      total: 0,
      query: '',
      order: 'name'
    };
    vm.search = search;
    vm.query = query;
    vm.paginate = paginate;
    vm.reorder = reorder;

    // Drop the case for a query string search
    if ($stateParams.q !== '') {
      vm.meta.query = $stateParams.q.toLowerCase();
    }

    // Run initial search
    search();

    // Run the search when the state changes
    // In the state (set in the routes file) we use reloadOnSearch = false so we can change states as the user types in the search field without losing focus on the input field
    $scope.$on('$locationChangeSuccess', function () {
      search();
    });

    // Run the search
    function search() {

      var args = {
        search: vm.meta.query,
        limit: vm.meta.limit,
        page: vm.meta.page,
        total: 'true',
        inc: 'Vehicle,PaymentMethod'
      };

      // Get the vehicle if required
      if ($stateParams.vehicleId && !vm.vehicle) {
        Restangular.one('vehicles', $stateParams.vehicleId).get().then(function (response) {
          vm.vehicle = response.data;
          runSearch();
        });
      } else {
        runSearch();
      }

      function runSearch() {
        var rest;
        if ($stateParams.vehicleId) {
          rest = Restangular.one('vehicles', vm.vehicle.id);
        } else {
          rest = Restangular.one('events', vm.event.id);
        }

        rest.all('deposits').getList(args).then(function (deposits) {
          vm.meta.total = deposits.meta.total;

          for (var i = 0; i < deposits.length; i++) {
            deposits[i].total = $filter('dollars')(deposits[i].total);
          }

          vm.deposits = deposits;
        });
      }
    }

    // Callback for query changes
    function query() {
      vm.meta.page = 1; // reset search to first page for new query
      $state.go('.', {q: vm.meta.query, p: vm.meta.page});
    }

    // Callback for md-on-paginate
    function paginate(page, limit) {
      $state.go('.', {q: $stateParams.q, p: page});
    }

    function reorder(order) {
      console.log(order);
    }

    function exportExcel() {

      var fileName = vm.event.name + ' Batch File ' + moment().format('DD-MM-YYYY') + '.xlsx';

      $http.get(API_URL + '/events/' + vm.event.id + '/rfds.xlsx', {responseType: 'arraybuffer'}).then(function (response) {
        var blob = new Blob([response.data], {type: response.headers('Content-type')});
        FileSaver.saveAs(blob, fileName);
      }, function(error) {
        alert('Download error:', error);
      });
    }

    function exportCSV() {

      var url = API_URL + '/events/' + vm.event.id,
        fileName = 'Deposits - ' + vm.event.name;

      if (vm.vehicle) { // filter by vehicle
        url += '/vehicles/' + vm.vehicle.id;
        fileName += ' - ' + vm.vehicle.number;
      }

      fileName += '.csv';

      url += '/deposit-entries.csv';

      $http.get(url).then(function (deposits) {
        var blob = new Blob([deposits.data], {type: 'text/csv'});
        FileSaver.saveAs(blob, fileName);
      });
    }

    // Go to the appropriate add deposit screen
    function goToAdd() {
      if ($stateParams.vehicleId) {
        $state.go('events.vehicles.deposits.add', {eventId:vm.event.id, vehicleId:$stateParams.vehicleId});
      } else {
        $state.go('events.deposits.add', {eventId:vm.event.id});
      }
    }
  }
}());
